define(['$window'], ($window) => {


  const westendHeader = () => {
    const component = {};
    const _config = {
      selectors: {
        stickyEl: '[data-stickEvent]',
        mobileStickyEl: '[data-mobileStickEvent]',
      },
      classNames: {
        stickyHeader: 'westendHeader-sticky',
        mobileStickyHeader: 'westendHeader-stickyMobile',
        bodyPadding: 'westendHeader-active'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.bind();
      return component;
    };

    const _bind = () => {
      const stickyEl = component.element.querySelector(component.config.selectors.stickyEl);

      if (stickyEl) {
        $window.addEventListener('scroll', component.doScroll);
      }
    };

    const _doScroll = () => {
      const scrollPos = $window.pageYOffset;
      component.stickyHeader(scrollPos);
    };

    const _stickyHeader = (scrollPos) => {
      const mobileStickyEl = component.element.querySelector(component.config.selectors.mobileStickyEl);
      mobileStickyEl.classList.add(component.config.classNames.mobileStickyHeader);

      if( scrollPos >= 32) {
        component.element.classList.add(component.config.classNames.stickyHeader);
        document.body.classList.add(component.config.classNames.bodyPadding);
      } else {
        component.element.classList.remove(component.config.classNames.stickyHeader);
        document.body.classList.remove(component.config.classNames.bodyPadding);
      }
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.doScroll = _doScroll;
    component.stickyHeader = _stickyHeader;

    return component;
  };

  return westendHeader;
});
